.docs {
  display: flex;
  background: #ebebeb;
  color: #1f1f1f;
  padding-top: 12px;
  margin-bottom: 10px;
}

.docs .tree-body-view {
  width: 300px;
}

.docs .level-1-padding {
  padding-left: 0px;
}

.docs .level-2-padding {
  padding-left: 20px;
}

.docs .level-3-padding {
  padding-left: 40px;
}

.docs .level-4-padding {
  padding-left: 60px;
}

.docs .level-5-padding {
  padding-left: 80px;
}

.docs .menu {
  cursor: pointer;
}

.docs .doc-view-container {
  background-color: #fff;
  display: flex;
  flex: 1;
  max-height: calc(100vh - 95px - 30px);
  overflow: auto;
  margin-right: 5px;
  padding-left: 10px;
  font-family: Arial;
  font-size: 11pt;
}

.docs .tree-body-view {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.docs table,
.docs th,
.docs td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-left: 2px;
}

.docs .line-image-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.docs .left-pane {
  padding-top: 5px;
  vertical-align: middle;
  color: #1f1f1f;
  font-size: 15px;
  font-weight: bold;
}

.docs .left-pane>span {
  display: inline-block;
  width: 250px;
  margin-left: 20px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.docs .left-pane>span:hover {
  background-color: #dbdbdb;
}

.docs .content {
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 1px 10px rgb(0, 0, 0, 0.3);
}

.docs .content>span {
  margin-left: 10px;
  display: inline-block;
}

.docs .content>span a {
  color: #000000;
  text-decoration: none;
}

.docs .content>span .Normal {
  font-size: 16px;
  margin: 10px;
}

.docs .headers-document {
  font-size: 18px;
  margin-top: 20px;
  font-family: Arial;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  color: DarkBlue;
}

.docs .first-header {
  margin-top: 10px;
}

.common-image-class {
  padding: 8px;
  max-width: calc(100% - 16px);
}

.image-margin {
  padding: 8px;
  max-width: calc(100% - 16px);
}

.bod-italic-text {
  font-weight: 600;
  font-style: italic;
}

.dark-view-container .doc-view-container p {
  color: #fff;
}

.dark-view-container .doc-view-container i {
  color: #fff;
}
.dark-view-container .doc-view-container li {
  color: #fff;
}

.dark-view-container .doc-view-container td {
  color: #fff;
  border: 1px solid #fff;
}

.dark-view-container .doc-view-container th {
  color: #fff;
  border: 1px solid #fff;
}

.dark-view-container .doc-view-container {
  background-color: #1f1f1f;
}

.dark-view-container .docs {
  background-color: #333;
}

.dark-view-container .tree-body-view .each-node-label {
  color: #fff;
}

.dark-view-container .tree-body-view .node-selected {
  background-color: #111;
}

.dark-view-container .tree-body-view .node-view-right-icon {
  color: #fff;
}

.dark-view-container .tree-body-view .each-node-view:hover {
  background-color: #111;
}

.dark-view-container .doc-view-container .headers-document {
  color: #bababa;
}

.dark-view-container .docs-view {
  background-color: #0f0f0f;
}

i.info-text {
  margin: 14px;
  text-decoration: solid;
}

.warning-icon {
  height: 20px;
  padding: 10px 16px;
  border: 2px solid black;
  border-radius: 50%;
  background: yellow;
  font-size: 18px;
  margin-right: 8px;
  font-weight: 900;
  line-height: 18px;
}

.info-icon {
  background: #57c5f7;
  height: 20px;
  padding: 10px 16px;
  border: 2px solid white;
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  color: white;
  margin-right: 8px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}