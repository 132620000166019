.views-container {
  z-index: 1000;
}

.release-notes-wrap {
  position: absolute;
  background-color: #fff;
  /* left: 0; */
}

.notes-heading {
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  font-weight: 600;
}

.notes-close-icon {
  cursor: pointer;
}

.release-note-container {
  min-height: 95vh;
  overflow: auto;
  max-height: calc(100vh - 188px);
  font-family: ABBvoice;
  padding: 0px 15px;
  font-size: 13px;
}

/* .release-note-container .font-italic {
  font-style: italic;
} */
.release-note-contentheaders {
  font-weight: 600;
  font-size: 15px;
}

.release-note-container li {
  line-height: 2;
}

.release-note-container .release-note-version {
  font-weight: 600;
  font-size: 13px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding-top: 10px;
}

.release-note-content {
  background-color: #fff;
  padding: 0px 25px;
  min-height: 94%;
}

.release-notes-header-wrap {
  display: flex;
  justify-content: space-between;
  color: #696969;
}

.release-note-time-stamp {
  padding-top: 10px;
}

.dark-view-container .release-notes-header-wrap {
  background-color: #333;
  color: #dbdbdb !important;
}

.dark-view-container .release-note-content {
  background-color: #333;
  color: #dbdbdb;
}

.dark-view-container .notes-heading {
  background-color: #333;
  color: #dbdbdb;
}