.headerClass {
  /* border-bottom: "1px solid #000"; */
  padding: "10px";
  column-width: "66px";
  max-width: "500px";
  display: "flex";
}

.iconClass {
  padding-bottom: 50px;
  /* /* margin: "4px"; */
  min-height: 40px;
  position: relative;
}

.importAndCheckBox {
  flex-direction: column;
  display: flex;
  position: relative;
  /* padding: 4px; */
  justify-content: space-between;
  margin: 5px;
}
.checkBox {
  position: relative;
  left: -10px;
  /* padding: 4px; */
  justify-content: space-between;
  margin: 5px;
}
.importButton {
  display: "flex";
  flex-direction: "row-reverse";
  /* margin-top: "20px"; */
  /* margin-bottom: "20px"; */
  position: relative;
  bottom: 1px;
  right: 10px;
  /* margin-bottom: 0px; */
  margin-right: -25px;
  float: right;
}

.disabledIcon {
  background-color: #ebebeb;
  /* border: 2px solid #bababa; */
  color: rgba(31, 31, 31, 0.5);
  cursor: not-allowed;
}
