/* .App {
  text-align: center;
} */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}
body h4,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}
body {
  font-family: "ABBVoice";
  margin: 0;
}
.ABB_CommonUX_LoginScreen__root
  > .ABB_CommonUX_LoginScreen__header
  > .ABB_CommonUX_LoginScreen__logo {
  /* background: url(/logo.png) no-repeat !important; */
  background-size: contain;
  display: block;
  height: 50px;
  width: 80px;
}
.ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 16px 32px 8px 32px !important;
}
.light-theme {
  background-color: #fff;
  color: #1f1f1f;
}
.dark-theme {
  color: #dbdbdb !important;
  background-color: #333 !important;
}
.content-dark-theme {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
}
.dark-view-container {
  background-color: #333 !important;
}

.ABB_CommonUX_AbbBar__root {
  height: 3rem;
}

.ABB_CommonUX_AbbBar__productName {
  font-size: 1.25em;
}

.rct-tree-root {
  margin-top: 10px;
  background: none !important;
  font-family: inherit;
}

.rct-tree-root:not(.rct-tree-root-focus)
  .rct-tree-item-title-container-focused {
  border: none !important;
}

.rct-tree-item-arrow-hasChildren.rct-tree-item-arrow svg {
  margin-top: 5px;
}

.content-area {
  background-color: white;
  min-height: calc(100vh - 7rem);
  margin: 20px;
  padding: 10px 20px;
}

.error-notification {
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid #bababa;
  min-width: 40%;
  max-width: 40%;
  /* overflow-y: auto; */
  min-height: 40%;
  max-height: 40%;
  z-index: 1;
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__disabled {
  background-color: #ebebeb;
  border: 2px solid #ebebeb;
  color: rgba(31, 31, 31, 0.5);
  cursor: not-allowed;
}

.Spinner {
  background: rgba(51, 51, 51, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  align-items: center;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.SpinnerBackground {
  position: fixed;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.SpinnerOverlay {
  background: rgba(51, 51, 51, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.parent {
  background: #cccccc;
  height: 200px;
  width: 200px;
  position: relative;
}

.child {
  background: #ffff00;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rct-tree-root img {
  height: 12px;
  padding: 9px;
}
