.rightMenuItems {
  float: right;
}

.rightMenuItems
  > .ABB_CommonUX_AppTopNavi__root
  > .ABB_CommonUX_AppTopNavi__itemContainer
  > .ABB_CommonUX_AppTopNavi__item
  > .ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__alignLeft {
  left: -180px;
}

.complexTree > .root {
  --rct-color-tree-bg: #f6f8fa;
  --rct-color-tree-focus-outline: #bababa;
  --rct-color-focustree-item-selected-bg: #bababa;
  --rct-color-focustree-item-focused-border: #bababa;
  --rct-color-focustree-item-draggingover-bg: #ecdede;
  --rct-color-focustree-item-draggingover-color: inherit;
  --rct-color-search-highlight-bg: #7821e2;
  --rct-color-drag-between-line-bg: #cf03d6;
  --rct-color-arrow: #9aa5b3;
  --rct-item-height: 30px;
}

.complexTreeError > .root {
  --rct-color-tree-bg: #f6f8fa;
  --rct-color-tree-focus-outline: #bababa;
  --rct-color-focustree-item-selected-bg: #ff000f;
  --rct-color-focustree-item-focused-border: #bababa;
  --rct-color-focustree-item-draggingover-bg: #ecdede;
  --rct-color-focustree-item-draggingover-color: inherit;
  --rct-color-nonfocustree-item-selected-bg: #ff000f;
  --rct-color-search-highlight-bg: #7821e2;
  --rct-color-drag-between-line-bg: #cf03d6;
  --rct-color-arrow: #9aa5b3;
  --rct-item-height: 30px;
}

.rct-tree-root,
.rct-tree-root * {
  border: 0;
  outline: 0;
  font-family: "ABBvoice", sans-serif;
  --rct-color-tree-bg: #f6f8fa;
  --rct-color-tree-focus-outline: #bababa;
  --rct-color-focustree-item-selected-bg: #bababa;
  --rct-color-focustree-item-focused-border: #bababa;
  --rct-color-focustree-item-draggingover-bg: #bababa;
  --rct-color-focustree-item-draggingover-color: inherit;
  --rct-color-nonfocustree-item-selected-bg: #bababa;
  --rct-color-search-highlight-bg: #7821e2;
  --rct-color-drag-between-line-bg: #bababa;
  --rct-color-arrow: #9aa5b3;
  --rct-item-height: 30px;
}

.rct-tree-root .rct-tree-item-button-focused {
  font-weight: bold;
}

.setOpacity {
  opacity: 0.5;
}
/* html,
body {
  height: 100%;
} */
.CardContainerClassLeft {
  width: 49%;
  float: left;
}

.CardContainerClassRight {
  width: 50%;
  float: right;
}

.rc-menu-horizontal {
  display: flex;
  padding: 0 5px;
}

.rc-menu-horizontal .ABB_CommonUX_Menu__menuItem {
  height: 40px;
}

.rc-menu-horizontal li > span {
  display: flex;
  flex-direction: column;
}

.rc-menu-horizontal .ABB_CommonUX_Menu__menuItemIcon {
  margin: 0 auto;
  padding: 0.25rem;
}

.rc-menu-horizontal .ABB_CommonUX_Menu__menuItemTitle {
  margin: 0 auto;
  padding: 0 0.5rem;
}

.ABB_CommonUX_Menu__subMenuPopup.ABB_CommonUX_Menu__light {
  background: #fff;
  box-shadow: 0 6px 12px rgba(15, 15, 15, 0.35);
  max-height: 120px;
  overflow-y: auto;
}

.highLightError:hover {
  background-color: #ebebeb;
  border-left: 3px solid #f03040;
}

.highLightError:focus {
  background-color: #ebebeb;
  border-left: yellow;
}

.selectedHighLightError {
  background-color: #ebebeb;
  border-left: 3px solid #f03040;
}

.iconHighlight {
  left: 300px;
}

.errorList {
  padding-left: "5px";
  text-decoration-color: "black";
}
.displayGrey {
  color: #9aa5b3;
}

.warningIcon {
  left: 255px;
}

.validationContainer {
  margin-top: 10px;
}
.validation-dark {
  background-color: #333;
  color: white;
}
.root-img1 {
  height: 12px;
  padding: 3px;
}

.validationWidth {
  width: 100%;
  display: flex;
  margin-top: -10px;
  border-bottom: 1px solid #bababa;
}

.validationHeader {
  width: 50%;
  float: left;
  padding-left: 10px;
}

.validationButton {
  float: right;
  padding-left: 257px;
  margin-top: 15px;
}

.validationList {
  width: 100%;
  margin-top: 0px;
  overflow-y: auto;
  min-height: 165px;
  max-height: 165px;
  height: 10%;
  border-bottom: 1px solid #bababa;
}

.leftBar {
  border-left: 3px solid #f03040;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.popupNewAssociation {
  position: relative;
  height: 280px;
  width: 650px;
  max-width: 700px;
}
.popupExistingAssociation {
  height: 180px;
  position: relative;
  width: 600px;
  max-width: 700px;
  justify-items: center;
  justify-content: center;
}
.flexColumnSource {
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 30px;
  /* justify-content: space-between; */
}
/* .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 13px 38px 27px 31px !important;
} */
.mainDivElement {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.flexColumn {
  display: flex;
  align-items: center;
  /* overflow-wrap: normal; */
  word-break: break-all;
  /* flex-direction: row; */
  width: 85%;
  /* padding-top: 8.5px; */
  line-height: 10px;
  /* padding: 10px; */
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.ellipsisClass {
  position: absolute;
  right: 5px;
}
.nodeTypeImage,
.nodeTypeImage i {
  line-height: 30px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.folderIcon {
  margin-right: 6px;
}

.errorIcon {
  margin-right: 15px;
  float: right;
  position: absolute;
  right: 30px;
}

.errorIcon-notification {
  margin-right: 6px;
  float: right;
  right: 10px;
}

.totalError-Container {
  padding-left: 500px;
  font-family: "ABBvoice", sans-serif;
  margin-top: 2px;
}

.padding {
  padding-top: 10px;
}

.searchElements {
  background-color: #aacded;
}
html,
body {
  height: 100%;
}
.treeElementClass {
  background-color: #ebebeb;
  margin-top: 10px;
  /* max-height: 600px; */
  overflow-y: scroll;
  height: calc(100vh - 325px);
  min-height: 400px;
}
.treeElementClassI {
  background-color: #ebebeb;
  margin-top: 10px;
  /* max-height: 600px; */
}
.searchedScrollKeys {
  background-color: #f03040;
}

.inputSearch {
  display: flex;
}

.searchIcon {
  display: grid;
  margin: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.searchIcon i:hover {
  color: #f03040;
}

.inputWidth {
  width: 90%;
}

.messagesAssociate {
  margin-top: -15px;
}

.error-tree-border {
  border-left: 3px solid #f03040;
  margin-left: -55px;
}
.lineIcons {
  position: absolute;
  left: -10px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.image-1 {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
